//课程管理
<template>
  <div class="product_management">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <!-- 内容部分 -->
        <div class="product_management_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_info margin_top_14 margin_bom_4">
            <!-- 课程编码 -->
            <div>课程编码：</div>

            <!-- 课程编码 -->
            <el-input clearable v-model="lessonValue" placeholder="请输入课程编码或课程名称"></el-input>

            <!-- 搜索 -->
            <!-- <div :class="[isArrowSearch?'search_bt_active':'search_bt_normal']" class="com_bt_action  margin_left_14" @click.stop="search">搜索</div> -->
          </div>

          <!-- 表格部分 -->
          <div class="dv_table_view_content">
            <el-table ref="table_pm" style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号 -->
              <el-table-column type="index" label="序号" align="center" width="80">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 课程编码 -->
              <el-table-column prop="sub_code" label="课程编码" width="140"></el-table-column>

              <!-- 课程名称  -->
              <el-table-column prop="sub_name" :show-overflow-tooltip="true" label="课程名称" width="180"></el-table-column>

              <!-- 所属年龄段  -->
              <el-table-column prop="age_range" label="所属年龄段" min-width="20%"></el-table-column>

              <!-- 加盟校区数  -->
              <el-table-column prop="school_count" :show-overflow-tooltip="true" label="加盟校区数" min-width="20%"></el-table-column>

              <!-- 累计学习人数  -->
              <el-table-column prop="student_count" label="累计学习人数" min-width="20%"></el-table-column>

              <!--  操作 -->
              <el-table-column label="操作" min-width="20%">
                <template slot-scope="scope">
                  <el-button @click="doLookAction(scope.row)" :disabled="scope.row.state == -2" type="text" size="small">查看</el-button>
                </template>
              </el-table-column>
            </el-table>

            <!-- 底部的分页器部分 -->
            <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>
<script>
//导入防抖节流函数
import { debounce } from "@/utils/throttle.js";
export default {
  data() {
    return {
      showLoading: -1,

      //课程编码
      lessonValue: "",

      //搜索按钮是否高亮
      isArrowSearch: false,

      //表格的最大高度
      maxHeight: 0,

      //表格部分的最小宽度
      tableWidth: 0,

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格数据
      tableData: [],
    };
  },
  created() {
    //初始化数据
    this.initData();
    //请求服务器数据
    this.httpData();
  },
  methods: {
    //初始化表格数据
    initData() {
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 210;
      //获取表格宽度
      this.tableWidth = this.getTabWidth();
    },

    /**
     * sub_id_name  搜索的课程编码
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;
      //课程编码
      if (!this.isEmpty(this.lessonValue)) {
        param.sub_id_name = this.lessonValue;
      } else {
        param.sub_id_name = "";
      }

      this.$http
        .fetchPost(this.$api.SUBJECT_MANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;

          //将表格滚动到顶部
          this.$nextTick(() => {
            this.$refs.table_pm.bodyWrapper.scrollTop = 0;
          });

          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.data_total);

            //先清空一下数据
            this.tableData.splice(0, this.tableData.length);

            if (this.totalSize != 0) {
              //证明有数据
              this.isShowEmptyView = false;

              //获取列表数据
              this.tableData = resulte.data.data_list;

              //组装一下表格数据,显示省市区的地址信息
              this.tableData.forEach((item, index) => {
                //设置索引
                this.$set(item, "index", index);
              });
            } else {
              //显示空视图
              this.isShowEmptyView = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //搜索的方法
    search() {},

    //查看条目
    doLookAction(item) {
      //跳转到查看课程界面
      this.$router.push({
        path: "/Home/LookLessons",
        query: {
          sub_code: item.sub_code,
          sub_name: item.sub_name,
          pid: item.pid,
          self_sub_id: item.self_sub_id,
        },
      });
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },
  },
  computed: {},
  components: {},
  watch: {
    //监听课程编码的输入值的变化
    lessonValue: debounce(function () {
      //刷新页面数据
      this.currPage = 1;
      this.httpData();
    }, 500),
  },
};
</script>
<style lang="scss" scoped>
.product_management {
  height: calc(100vh - 77px);
  min-width: $min_width;

  //内容部分
  .product_management_content {
    position: relative;
    margin: 0 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    ::v-deep .el-table .cell {
      text-align: center;
    }

    //顶部的操作栏部分
    .top_info {
      display: flex;
      align-items: center;

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }
  }
}
</style>
